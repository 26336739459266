
    <nb-card>
      <nb-card-header> {{type ? 'Error':'Sucess'}}</nb-card-header>
      <nb-card-body>{{message}}</nb-card-body>
      <nb-card-footer class="text-center">
        <button
          nbButton
          status="primary"
          outline
          class="mr-3"
          (click)="ref.close()"
          size="small"
        >
          Okay
        </button>
        <!-- <button nbButton status="basic" outline (click)="ref.close()">No</button> -->
      </nb-card-footer>
    </nb-card>
