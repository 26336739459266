import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
@Component({
  selector: 'ngx-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent implements OnInit {
message: any;
type: any = '';
  constructor(public ref: NbDialogRef<AlertMessageComponent>, private dialogService: NbDialogService) { }

  ngOnInit(): void {


  }


}
