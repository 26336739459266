import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router, public apiService: AppService) {

  }

  canActivate() {
    if (this.apiService.getCredentials()) {
     
      return true;
    } else {
     
      this.router.navigate(['admin/login'])
      return false;

    }
    
  }
}
