import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as moment from 'moment';
export const baseUrl = 'https://app.imsafe.ae:3000';
//export const baseUrl = 'https://apps.rxhub.io:3000';
// export const baseUrl = 'http://64.225.85.224:3000';
export interface GoogleObj {
  q: string[];
  target: string;
}
@Injectable({
  providedIn: 'root',
})

export class AppService {
  private projectChangedSource = new Subject<string>();
  // Observable string streams
  projectChanged$ = this.projectChangedSource.asObservable();
  //baseUrl = 'https://apps.rxhub.io:3000'; //live api link
  //  baseUrl = 'https://app.imsafe.ae:3000'; //live api link



  // baseUrl = 'https://apps.rxhub.io:3000'; //live api link
  // baseUrl = 'https://app.rxhub.io:3002'; // dev  api link
  // baseUrl = 'http://167.99.244.219:3002';
  baseUrl = 'https://app.imsafe.ae:3000'

  apiUrl = this.baseUrl + '/admin';
  // media_url = this.baseUrl + '' + '/public/uploads/';
  media_url = 'https://sgp1.digitaloceanspaces.com/rxhubspace/Uploads/Images/';
  admin_media_url = this.baseUrl + '' + '/public/admin_uploads/';


  video_url: 'https://v3-bucket.s3.us-east-2.amazonaws.com/uploads/video/';
  constructor(private http: HttpClient, private router: Router) { }
  // Service message commands
  projectChanged(value: any) {
    this.projectChangedSource.next(value);
  }
  // *********************************************************************************** //
  // **********************************Admin apis ************************************** //
  // *********************************************************************************** //
  adminLogin(req) {
    let headers = new HttpHeaders();
    headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post<any>(this.apiUrl + '/login', req, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  changePasswordAdmin(request) {
    let headers = new HttpHeaders();

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(this.apiUrl + '/password/change', request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  updateMergeCode(limit, offset) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = `${this.apiUrl}/new-instruction?limit=${limit}&offset=${offset}`;


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, null, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  handleRequest(data) {
    let headers = new HttpHeaders();

    // let time_zone = this.getTimeZone();
    // time_zone = time_zone.replace('+', '%2B');
    const url = `${this.apiUrl}/contact-us?email=${data.email}&status=${data.status}`;


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, null, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDashboard(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/dashboard' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(this.apiUrl + '/dashboard', { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getBackup(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(this.apiUrl + '/backup-db', { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }


  getUserDetails(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/user/' + request.id + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDrugDetails(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/medicine/' + request.id;


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDrugSpecailNotes(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/notes/' + request.id + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getQuestionDetails(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/question/' + request.id;


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getUsers(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/user' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getAllContact(request) {
    let headers = new HttpHeaders();
    const url = this.apiUrl + '/contact-us'
    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getallUsers(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/user-all' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDiseases(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/disease' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getBrands(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/brand' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDesignationCat(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation-cat' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDesignationSubCat(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDesignations(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getFaqlist(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/faq' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getStaticContent(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/static-content';


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDynamicContent(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/dynamic/content';


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getHomeScreenImages() {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/home-screen-images' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getEnquiries(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/enquiry' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getQuestion(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/question' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getCategory(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/category' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getSystems(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/system' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDrugForm(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/drug/form' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDrugGeneric(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/generic' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getBodyParts(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/body-parts' + '?time_zone=' + (time_zone);
    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getSymptoms(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/symptoms' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getDrugs(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/medicine';


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, params: request }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  getCatsList(isEditing = null) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    let url = this.apiUrl + '/cats-list' + '?time_zone=' + (time_zone);
    if (isEditing) { url += ('&isEditing=' + isEditing); }

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugDesignations(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designations/' + request.id;


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugProfessions(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation-cat-languages?designation_category_id=' + request.designation_category_id;


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  bodyPartsAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/body-parts';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  uploadHomeScreenImage(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    // const url = this.apiUrl + '/upload' + '?file=' + request.file+'&upload_in_param'+request.upload_in_param;
    const url = this.apiUrl + '/upload';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, { headers, file: request.file, upload_in_param: request.upload_in_param }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  symptomAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/symptoms';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  faqAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/faq';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  replyEnquiry(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/enquiry';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugsImport(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/import/medicines';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  sendNotification(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/notification';
    request.id = (request.id).toString();
    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  medicineAdd(request) {
    let headers = new HttpHeaders();


    const url = this.apiUrl + '/medicine';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }

  updateProfessionList(request = null) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation-cat';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }

  bodyPartsUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/body-parts';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  inquiryStatusUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/enquiry/status';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  dynamicContentUpdate(request) {
    let headers = new HttpHeaders();


    const url = this.apiUrl + '/dynamic/content';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  staticContentUpdate(request) {
    let headers = new HttpHeaders();


    const url = this.apiUrl + '/static-content';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  faqUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/faq';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  symptomUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/symptoms';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  medicineUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/medicine';

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  blockUnblockUser(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/user' + '?time_zone=' + (time_zone);

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  diseaseUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/disease' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugFormUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/drug/form' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugGenericUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/generic' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  diseaseAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/disease' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugFormAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/drug/form' + '?time_zone=' + (time_zone);

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugGenericAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/generic' + '?time_zone=' + (time_zone);

    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  systemUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/system' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  systemAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/system' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  brandUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/brand' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  categoryDesignationUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation-cat' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  subcategoryDesignationUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  designationUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  categoryUpdate(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/category' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.put<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  brandAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/brand' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  categoryDesignationAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation-cat' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  designationSubCatAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  designationAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/designation' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  categoryAdd(request) {
    let headers = new HttpHeaders();

    let time_zone = this.getTimeZone();
    time_zone = time_zone.replace('+', '%2B');
    const url = this.apiUrl + '/category' + '?time_zone=' + (time_zone);


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    return this.http.post<any>(url, request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  uploadImage(data) {

    return this.http.post(this.baseUrl + '/api/upload-do', data, {
      reportProgress: true,
      observe: 'events',
    });
  }
  diseaseDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/disease', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  questionDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/question', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugGenericDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/generic', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  drugFormDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/drug/form', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  systemDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/system', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  brandDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/brand', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  designaionCatDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/designation-cat', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  designaionSubCatDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/designation', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  designaionDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/designation', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  enquiryDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/enquiry', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  categoryDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/category', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  bodyPartDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/body-parts', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  symptomDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/symptoms', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  faqDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/faq', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  medicineDelete(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/medicine', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  removeReportedPost(request) {
    let headers = new HttpHeaders();


    headers = new HttpHeaders()
      .set('Authorization', this.getAccessToken())
      .set('Content-Type', 'application/json');
    const data = {
      headers,
      body: request,
    };
    return this.http.delete<any>(this.apiUrl + '/post_reports/remove', data).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map(this.extractData),
    );
  }
  // ***********************************************************************************//
  // **********************************Common apis **************************************//
  // ***********************************************************************************//
  /*
     * extractData function returns outer response
     */
  private extractData(res: any) {

    return res;
  }
  /*
  * handleError function for HTTP error handling
  */
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {

    } else {
    }
    // return an observable with a meaningful error message to the end user
    return throwError(
      errorResponse,
      // "There is a problem with the service. We are notified & working on it. Please try again later."
    );
  }
  translate(obj: GoogleObj) {
    const url = 'https://translation.googleapis.com/language/translate/v2?key=';
    const key = 'AIzaSyCeYMODRiow1T4SCnWB6zenTujCkROdCeA';
    return this.http.post(url + key, obj);
  }
  getTimeZone() {
    return moment().format('Z');
  }
  setCredentials(res) {
    localStorage.setItem('session', JSON.stringify(res));
  }
  getCredentials() {


    return JSON.parse(localStorage.getItem('session'));
  }
  getAccessToken() {
    const session = this.getCredentials();
    const accessToken: any = 'Bearer' + ' ' + session.token;

    return accessToken;
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
