<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        Change password
      </div>
      <div>
        <!-- <div class="position-relative upload-csv">
                  <input type="file"
                  class="form-control-file"
                   (change)="onSelectFile($event)">
                  <button nbButton size="small" outline status="info">{{edit_data.id ? 'Edit Image' : 'Add Image'}}</button>
              </div> -->
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form
      (ngSubmit)="changePassword(form)"
      #form="ngForm"
      aria-labelledby="title"
    >
      <div class="row">
        <input
          type="text"
          [(ngModel)]="data.old_password"
          #id="ngModel"
          name="id"
          hidden
        />
        <!-- <div class="col-sm-12">
              <div class="form-group mb-1 text-center" [nbSpinner]="image_uploading" nbSpinnerStatus="primary">

                <img *ngIf="url"   [(ngModel)]="data.image"
                #image="ngModel"
               name="image" ngDefaultControl [src]="url" height="110" alt="No image"> <br/>
          </div>
      </div> -->
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="label">Old Password</label>
            <!-- <input type="text" nbInput fullWidth  placeholder="Body Part Name"> -->

            <input
              nbInput
              fullWidth
              [(ngModel)]="data.old_password"
              #old_password="ngModel"
              name="old_password"
              id="input-name"
              placeholder="old password"
              autofocus
              value=""
              type="password"
              required
            />
            <ng-container
              *ngIf="
                (old_password.invalid && old_password.touched) || form.submitted
              "
            >
              <p
                class="error-message text-danger"
                *ngIf="old_password.errors?.required"
              >
                Old password is required!
              </p>
              <!-- <p class="error-message text-danger" *ngIf="name.errors?.pattern">
       Email should be the real one!
     </p> -->
            </ng-container>
          </div>
          <div class="form-group">
            <label class="label">New Password</label>
            <!-- <input type="text" nbInput fullWidth  placeholder="Body Part Name"> -->

            <input
              nbInput
              fullWidth
              [(ngModel)]="data.new_password"
              #new_password="ngModel"
              name="new_password"
              id="input-name"
              placeholder="New password"
              autofocus
              value=""
              type="password"
              (input)="checkPassword()"
              required
            />
            <ng-container
              *ngIf="
                (new_password.invalid && new_password.touched) || form.submitted
              "
            >
              <p
                class="error-message text-danger"
                *ngIf="new_password.errors?.required"
              >
                New password is required!
              </p>
              <!-- <p class="error-message text-danger" *ngIf="name.errors?.pattern">
       Email should be the real one!
     </p> -->
            </ng-container>
          </div>
          <div class="form-group">
            <label class="label">Confirm Password</label>
            <!-- <input type="text" nbInput fullWidth  placeholder="Body Part Name"> -->

            <input
              nbInput
              fullWidth
              (input)="checkPassword()"
              [(ngModel)]="data.confirm_password"
              #confirm_password="ngModel"
              name="confirm_password"
              id="input-name"
              placeholder="confirm password"
              autofocus
              value=""
              type="password"
              required
            />
            <ng-container
              *ngIf="
                (confirm_password.invalid && confirm_password.touched) || form.submitted
              "
            >
              <p
                class="error-message text-danger"
                *ngIf="confirm_password.errors?.required"
              >
                confirm password is required!
              </p>

              <!-- <p class="error-message text-danger" *ngIf="name.errors?.pattern">
       Email should be the real one!
     </p> -->
            </ng-container>
            <ng-container
              *ngIf="
                (!pwd_match) && form.submitted && confirm_password.valid
              "
            >

              <p
                class="error-message text-danger"
                *ngIf="!pwd_match"
              >
                password did'nt match!
              </p>
              <!-- <p class="error-message text-danger" *ngIf="name.errors?.pattern">
       Email should be the real one!
     </p> -->
            </ng-container>
            <ng-container
              *ngIf="
              error
              "
            >

              <p
                class="error-message text-danger"

              >
                {{error}}
              </p>
              <!-- <p class="error-message text-danger" *ngIf="name.errors?.pattern">
       Email should be the real one!
     </p> -->
            </ng-container>
          </div>
        </div>
      </div>
      <!-- <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="label d-block">Gender</label>
          <nb-select placeholder="-- select gender --" fullWidth [(ngModel)]="data.gender"
          #gender="ngModel"
          name="gender"  required>
              <nb-option value="" selected disabled>-- select gender --</nb-option>
              <nb-option value="2">Male</nb-option>
              <nb-option value="1">Female</nb-option>
              <nb-option value="3">Both</nb-option>
          </nb-select>
          <ng-container *ngIf="gender.invalid && gender.touched || form.submitted">
            <p class="error-message text-danger" *ngIf="gender.errors?.required">
              Select the gender
            </p>

          </ng-container>
      </div>
      </div>
    </div> -->

      <div class="d-flex justify-content-center mt-4">
        <button
          type="submit"
          [disabled]="is_loading"
          size="small"
          status="primary"
          class="d-block mr-4"
          nbButton
        >
          Submit
        </button>
        <button
          type="button"
          size="small"
          status="basic"
          class="d-block"
          nbButton
          (click)="ref.close()"
        >
          Cancel
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
