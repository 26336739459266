import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NgForm } from '@angular/forms';
import { AppService } from '../../app.service';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { format } from 'path';
@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  message: any;
  type: any = '';
  data: any = {};
  pwd_match: boolean;
  is_loading: boolean;
  error: any;
  constructor(
    public ref: NbDialogRef<ChangePasswordComponent>,
    private dialogService: NbDialogService, public apiService: AppService,
  ) {}

  ngOnInit(): void {
    let form: NgForm;
    if (form.valid && this.pwd_match){
      this.ref.close();

     }
}
  changePassword(form: NgForm) {

    this.error = '';
    this.message = '';

    if (form.valid && this.pwd_match) {
      this.apiService.changePasswordAdmin(form.value).subscribe(res => {

        this.dialogService.open(AlertMessageComponent, {
          context: {message: res.message},
        },
        );
        this.ref.close();
      },
      (err) => {

        this.error = err.error.error_description;

      });

    }
    (err) => {

      this.error = err.error.error_description;

    };

  }


  checkPassword() {

    if (this.data.new_password && this.data.confirm_password) {
      this.pwd_match = this.data.new_password === this.data.confirm_password ? true : false;


    }
  }
}
